.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.background {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.imageContainer {
  padding: 0 10px;
}

.imageContainer img {
  width: 30vw;
}

/* Media query per dispositivi mobili */
@media (max-width: 768px) {
  .imageContainer img {
    width: 80vw;
  }
}

.mt {
  margin-top: 200px;
}
.scritta {
  font-size: xx-large;
  font-family: "Merienda", cursive;
}
