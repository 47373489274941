@import url("https://fonts.googleapis.com/css2?family=Merienda&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Baskervville&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Satisfy&display=swap");

.bigButton {
  padding: 1.7rem 3rem;
  background: linear-gradient(-145deg, white 3%, antiquewhite);
  color: black;
  margin: 30px 10px;
  border-radius: 20px;
  font-size: 26px;
  text-decoration: none;
  font-family: "Merienda", cursive;
  font-weight: 200;
  backdrop-filter: blur(3rem);
  /* max-width: 1000px; */
  border: 1px solid beige;
  -webkit-box-shadow: 10px 19px 22px -12px rgba(0, 0, 0, 0.13);
  box-shadow: 10px 19px 22px -12px rgba(0, 0, 0, 0.13);
}
.smallButton {
  padding: 1rem 2rem;
  background: linear-gradient(-145deg, white, antiquewhite);
  color: black;
  margin: 16px 14px;
  border-radius: 14px;
  font-size: 16px;
  text-decoration: none;
  font-family: "Merienda", cursive;
  font-weight: 600;
  backdrop-filter: blur(3rem);
  max-width: 1000px;
  border: 1px solid beige;
  -webkit-box-shadow: 9px 13px 16px -9px rgba(0, 0, 0, 0.1);
  box-shadow: 9px 13px 16px -9px rgba(0, 0, 0, 0.1);
}
.blur {
  opacity: 0.2;
}
.border {
  border: 1px solid black !important;
}
.Menu {
  padding: 2rem 4rem;
  background: linear-gradient(rgb(255, 255, 255), rgba(255, 255, 255, 0.447));
}

.buttonContainer {
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  position: fixed;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  width: 100vw;
  bottom: 0px;
}

.diageoContainer img {
  max-width: 90px;
  height: auto;
}
