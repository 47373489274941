/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 36px;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
     background: #36373d; 
    height: 0px;
    border-radius: 10px;
    height: 4px !important;
  }
  
  
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
    display: none;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: transparent;
    padding: .5em 1.5em 0;
    font-size: 1.15em;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: transparent;
  }
  


  
  /* Individual item */
  .bm-item {
    display: inline-block;
  }
  
  /* Styling of overlay */
  

  /* Individual item */
.bm-item {
    display: inline-block;
    color: black;
    margin-bottom: 10px;
    text-align: left;
    text-decoration: none;
    margin-top: 50px;
    font-size: x-large;
    font-family: 'Merienda', cursive;
    /* transition: color 0.2s; */
  }
  
