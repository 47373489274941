.logoContainera{
    height: 1000px; 
    font-size: 30px;
    letter-spacing: 3px;
    position: static !important;
    width: 100%;
    top: 0 !important;
    left: 0;
    background-color: black;
    z-index: 1000;
    overflow: hidden;
}
.logo {
    z-index: 1000;
    position: fixed;
    width: 100vw;
}
