.animate {
  max-height: 0px;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-in, opacity 0.4s ease-in,
    max-height 0.2s ease-out, opacity 0.2s ease-out;
}

.animate.show {
  padding-top: 5px;
  opacity: 1;
  max-height: 220px;
}

.btnExpand {
  display: flex;
  flex-direction: row;
  max-width: 70%;
  align-items: center;
  justify-content: space-around;
}

/*


0.4s ease-out


*/
